<template>
	<v-container fluid>
		<please-select-utility>
			<v-stepper vertical v-model="step">

				<v-stepper-step step="1" :complete="true">
					Дружество
					<div class="mt-2 grey--text text--darken-1">{{ selectedUtility }}</div>
				</v-stepper-step>
				<v-stepper-content step="1"></v-stepper-content>

				<v-stepper-step step="2" editable :complete="step > 2" @click="onStepNavigate(2)">
					Шаблон
					<div class="mt-2 grey--text text--darken-1">{{ sessionTemplate ? tplLabel(selectedTemplate) : '' }}</div>
				</v-stepper-step>
				<v-stepper-content step="2">
					<v-progress-circular indeterminate v-if="loadingTemplates" />
					<v-radio-group v-show="!loadingTemplates" class="ml-2" v-model="sessionTemplate" @change="onSelectTemplate">
						<v-radio
							v-for="tpl in sessionTemplateList"
							:key="tpl.id"
							:value="tpl.id"
							:label="tplLabel(tpl)"
							@click="onSelectTemplate"
						></v-radio>
					</v-radio-group>
				</v-stepper-content>

				<v-stepper-step step="3" :complete="step > 3" :editable="step > 3">
					Вид сесия
					<div class="mt-2 grey--text text--darken-1"></div>
				</v-stepper-step>
				<v-stepper-content step="3">
					<v-radio-group class="ml-2" v-model="sessionType">
						<v-radio :value="0" @click="onSelectType" label="Редовна" />
						<v-radio :value="1" @click="onSelectType" label="Изравнителна" />
						<v-radio :value="2" @click="onSelectType" label="Корекция на изравнителна" />
						<v-radio :value="3" @click="onSelectType" label="Извънредна изравнителна" />
					</v-radio-group>
					<v-alert type="error" v-if="checkErrors">{{ checkErrors }}</v-alert>
					<v-alert type="info" v-if="progressSessionUUID" icon="mdi-alert">
						Нова сесия е в процес на създаване.
						<v-btn
							@click="seeSessionProgress"
							color="primary"
						>
							Виж статус
						</v-btn>
					</v-alert>
				</v-stepper-content>

				<v-stepper-step step="4" :complete="step > 4">Данни</v-stepper-step>
				<v-stepper-content step="4">
					<div v-if="sessionType === 0 && sessionDataType0" class="py-2" style="max-width: 500px;">
						<v-simple-table dense class="text-body-1" v-if="sessionDataType0['year']">
							<tbody>
							<tr>
								<th>Месец:</th>
								<td><year-month :year="sessionDataType0['year']" :month="sessionDataType0['month']" /></td>
							</tr>
							<tr>
								<th>Начало:</th>
								<td><date-format :date="sessionDataType0['date_start']" /></td>
							</tr>
							<tr>
								<th>Край:</th>
								<td><date-format :date="sessionDataType0['date_end']" /></td>
							</tr>
							</tbody>
						</v-simple-table>
						<v-divider/>
						<br>
						<v-form ref="dataForm0">
							<edit-input required outlined dense class="mb-3" name="price_1_mwh" :data="sessionDataType0" />
							<edit-input required outlined dense class="mb-3" name="price_1_mwh_novat" :data="sessionDataType0" />
							<edit-input required outlined dense class="mb-3" name="price_1m3_cold_water" :data="sessionDataType0" />
							<edit-input required outlined dense class="mb-3" name="price_1m3_cold_water_no_sewer" :data="sessionDataType0" />
							<edit-input required outlined dense class="mb-3" name="t_calculated" :data="sessionDataType0" />
							<edit-input required outlined dense class="mb-3" name="t_avg_building" :data="sessionDataType0" />
							<edit-input required outlined dense class="mb-3" name="hot_water_per_day_per_person_liters" :data="sessionDataType0" />
							<edit-input required outlined dense class="mb-3" name="max_hw_m3_to_mwh" :data="sessionDataType0" />
							<base-input type="checkbox" label="Копирай прогнозни дялове от последната изравнителна"
								v-model="sessionDataType0.copyProgn"
							/>
						</v-form>
					</div>

					<div v-else-if="sessionType === 1 && sessionDataType1" class="py-2" style="max-width: 500px;">
						<v-form ref="dataForm1">
							<session-select-base
								label="Първа сесия"
								v-model="sessionDataType1.first"
								outlined dense
								class="mb-4"
								hide-details
								:items="sessionDataType1.list"
							/>
							<session-select-base
								label="Последна сесия"
								v-model="sessionDataType1.last"
								outlined dense
								class="mb-4"
								hide-details
								:items="sessionDataType1.list"
							/>
						</v-form>
					</div>

					<div v-else-if="sessionType === 2 && sessionDataType2" class="py-2" style="max-width: 500px;">
						Последна изравнителна сесия:<br/>
						<session-item-description v-if="sessionDataType2.last_izr" :item="sessionDataType2.last_izr" />
					</div>

					<div v-else-if="sessionType === 3 && sessionDataType3" class="py-2" style="max-width: 500px;">
						<v-form ref="dataForm3">
							<session-select-base
								label="Първа сесия"
								v-model="sessionDataType3.first"
								outlined dense
								class="mb-4"
								hide-details
								:items="sessionDataType3.list"
							/>
							<session-select-base
								label="Последна сесия"
								v-model="sessionDataType3.last"
								outlined dense
								class="mb-4"
								hide-details
								:items="sessionDataType3.list"
							/>
						</v-form>
					</div>

					<v-btn color="primary" :loading="submitting" :disabled="submitting" @click="dataValidate">Създай нова сесия</v-btn>

					<confirm-dialog ref="submitConfirm" title="Създаване на нова сесия" @confirm="onSubmit">
						<div class="text-body-1">Моля потвърдете, че искате да създадете нова сесия с посочените параметри</div>
					</confirm-dialog>

					<v-alert type="error" class="mt-5" v-if="result && result.error">{{result.error}}</v-alert>

				</v-stepper-content>

				<v-stepper-step step="5">
					Край
				</v-stepper-step>
				<v-stepper-content step="5">
					<progress-tracker
						v-if="step===5 && progressSessionUUID"
						:uuid="progressSessionUUID"
					>
						<template #default="{progress, current, total, time_remaining}">
							<v-progress-linear
								v-if="progress < 1"
								rounded striped height="24" :value="progress * 100 || 0">
								{{ (progress * 100 || 0).toFixed(1) }}%
								({{current}}/{{total}} станции, остава {{time_remaining}})
							</v-progress-linear>
							<v-alert v-else-if="result && !result.error" type="success" dense class="mt-5">
								Сесията е създадена!
							</v-alert>
						</template>
					</progress-tracker>
				</v-stepper-content>

			</v-stepper>
		</please-select-utility>
	</v-container>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */

import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility";
import Model from "./Model";
import EditInput from "@/ittijs/Inputs/EditInput";
import BaseInput from "@/ittijs/Inputs/BaseInput";
import IModelMixin from "@/ittijs/IModelMixin";
import ConfirmDialog from "@/ittijs/ConfirmDialog";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import YearMonth from "@/ittijs/components/YearMonth";
import DateFormat from "@/ittijs/components/DateFormat";
import SessionSelectBase from "@/views/sessions/SessionSelectBase";
import SessionItemDescription from "@/views/sessions/SessionItemDescription";
import ProgressTracker from "@/ittijs/components/ProgressTracker";

const imodel = new Model();

export default {
	components: {
		ProgressTracker,
		SessionItemDescription,
		SessionSelectBase,
		DateFormat,
		YearMonth,
		ConfirmDialog,
		EditInput,
		BaseInput,
		PleaseSelectUtility,
	},
	mixins: [
		SelectedUtilityMixin,
		IModelMixin,
		RouteSyncMixin({
			utility: 'selectedUtility',
		})
	],
	props: [
		'utility',
	],
	data(){
		return {
			step: 2,
			sessionTemplate: null,
			sessionTemplateList: [],
			sessionDataType0: {},
			sessionDataType1: {},
			sessionDataType2: {},
			sessionDataType3: {},
			loadingTemplates: false,
			checkErrors: null,
			sessionType: null,
			progressSessionUUID: null,
			submitting: false,
			result: null,
		}
	},
	computed: {
		selectedTemplate(){
			return this.sessionTemplateList.find(item => item.id == this.sessionTemplate) || {}
		},
		step4Form(){
			switch (this.sessionType) {
				case 0: return this.$refs.dataForm0;
				case 1: return this.$refs.dataForm1;
				case 2: return true;
				case 3: return this.$refs.dataForm3;
			}
			return null;
		},
	},
	mounted() {
		if (this.selectedUtility) this.loadTemplatesList();
	},
	watch: {
		selectedUtility(val){
			if (val) {
				this.loadTemplatesList();
			}
		},
	},
	methods: {
		onStepNavigate(step){
			// no need to alter this.step
			switch (step) {
				case 2:
					this.result = null;
					break;
			}
		},
		loadTemplatesList(){
			this.sessionTemplate = null;
			this.sessionTemplateList = [];
			this.checkErrors = null;
			this.progressSessionUUID = null;
			this.step = 2;
			this.sessionDataType1 = {};
			this.loadingTemplates = true;
			this.result = null;
			this.sessionType = null;
			imodel.getTemplatesForNewSession(this.selectedUtility)
				.then(list=>{
					this.sessionTemplateList = list;
					if (list.length===1) {
						this.sessionTemplate = list[0].id;
						this.onSelectTemplate();
					}
				})
				.finally(()=>{ this.loadingTemplates = false })
			;
		},
		onSelectTemplate() {
			if (this.sessionTemplate) {
				this.step = 3;
			}
		},
		onSelectType(){
			if (this.sessionType !== null) {
				this.checkErrors = null;
				this.progressSessionUUID = null;

				let res;

				switch (this.sessionType) {
					case 0: res = imodel.checkCreateSession(this.selectedUtility, this.sessionTemplate); break;
					case 1: res = imodel.checkCreateSessionIzr(this.selectedUtility, this.sessionTemplate); break;
					case 2: res = imodel.checkCreateSessionIzrCorrection(this.selectedUtility, this.sessionTemplate); break;
					case 3: res = imodel.checkCreateSessionIzrIzvanredna(this.selectedUtility, this.sessionTemplate); break;
					default: return;
				}

				res
					.then(res=>{
						if (res.progress) {
							this.progressSessionUUID = res.progress;
						}
						else {

							switch (this.sessionType) {
								case 0:
									this.sessionDataType0 = {...res};
									this.$refs.dataForm0.resetValidation();
									break;
								case 1:
									this.sessionDataType1 = {...res};
									this.$refs.dataForm1.resetValidation();
									break;
								case 2:
									this.sessionDataType2 = {...res};
									break;
								case 3:
									this.sessionDataType3 = {...res};
									this.$refs.dataForm3.resetValidation();
									break;
								default: return;
							}

							this.step = 4;
						}
					})
					.catch(res=>{
						if (res.errors && res.errors.form) {
							this.checkErrors = res.errors.form.shift();
						}
					})
			}
		},
		tplLabel(tpl){
			if (!tpl) return '';
			let result = `${tpl.name || '[празно]'}`;
			result += ` - начало ден ${tpl.start_day_of_month}, офсет ${tpl.month_offset}`
			return result;
		},
		dataValidate(){
			if (this.step4Form && (this.step4Form === true || this.step4Form.validate())) {
				this.$refs.submitConfirm.open().then(res => res && this.onSubmit());
			}
		},
		async onSubmit(){
			if (this.submitting) return;
			this.submitting = true;
			switch (this.sessionType) {
				case 0:
					this.result = await imodel.createSession(this.selectedUtility, this.sessionTemplate, this.sessionDataType0);
					break;
				case 1:
					this.result = await imodel.createSessionIzr(this.selectedUtility, this.sessionTemplate, {
						sess_first: this.sessionDataType1.first,
						sess_last: this.sessionDataType1.last,
					});
					break;
				case 2:
					this.result = await imodel.createSessionIzrCorrection(this.selectedUtility, this.sessionTemplate, {
						last_izr: this.sessionDataType2.last_izr.id,
					});
					break;
				case 3:
					this.result = await imodel.createSessionIzrIzvanredna(this.selectedUtility, this.sessionTemplate, {
						sess_first: this.sessionDataType3.first,
						sess_last: this.sessionDataType3.last,
					});
					break;
			}
			this.submitting = false;
			if (!this.result['error']) {
				this.progressSessionUUID = this.result['create_progress_uuid'];
				this.step = 5;
			}
		},
		seeSessionProgress(){
			if (this.progressSessionUUID) {
				this.step = 5;
			}
		},
	},
	provide: function(){
		return {
			imodel,
		};
	},
}
</script>